// BAvatar custom styles

.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    outline: 0;
  }

  &a,
  &button,
  &.btn {
    padding: 0;
    border: 0;

    &:not(:disabled):not(.disabled) {
      cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
    }
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    opacity: $btn-disabled-opacity;
    pointer-events: none;
  }

  .b-avatar-custom,
  .b-avatar-text {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .b-avatar-text {
    text-transform: uppercase;
    white-space: nowrap;
  }

  > .b-icon {
    width: 60%;
    height: auto;
    max-width: 100%;
  }

  img {
    width: 90%;
    height: 90%;
    max-width: 100%;
    max-height: auto;
    border-radius: inherit;
  }

  .b-avatar-badge {
    // Positioning will be handled via inline styles
    position: absolute;
    min-height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
    line-height: 1;
    border-radius: 10em;
    font-size: 70%;
    font-weight: 700;
    z-index: 5;
  }
}
